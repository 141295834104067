
import { defineComponent, ref, onMounted, computed } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import InvoiceService from "@/core/services/car/InvoiceService";
import { onBeforeRouteLeave } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";
import MasterService from "@/core/services/car/MasterService";
import ShowQuoteRefNo from "@/components/reusables/showQuoteRefNo.vue";
import {customFunction, roles} from "@/store/stateless/store";
import mainSocket from "./../../../main-socket";
import InvoiceAction from "@/components/dropdown/car/InvoiceAction.vue";

interface InvoiceBodyParam {
  per_page: string;
  agent: string;
  payment_type: any;
  is_amend: number;
  status: any;
  is_discrepancy: number;
  keyword: string;
  start_date: string;
  end_date: string;
}

interface ApproveInvoiceParam {
  invoice_id: number;
  status: any;
}

export default defineComponent({
  name: "declined-invoice",
  components: {
    ShowQuoteRefNo,
    InvoiceAction
    // Overlay,
  },
  data() {
    return {
      // opened: false,
      // visible: false,
      shortcuts: [
        {
          text: "Last week",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          })()
        },
        {
          text: "Last month",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          })()
        },
        {
          text: "Last 3 months",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          })()
        }
      ],
      value1: "",
      // value2: "",
      invoiceStatus: [
        { id: "1", name: "Pending" },
        { id: "2", name: "Approve" },
        { id: "2", name: "Approve with Discrepancy" },
        { id: "3", name: "Decline" }
      ]
    };
  },
  setup() {
    // let customers_obj = ref([]);
    let responseResult = ref<any>({});
    responseResult.value.success = false;
    responseResult.value.failed = false;
    responseResult.value.execute = false;
    let approveInvoiceParam = {} as ApproveInvoiceParam;
    const store = useStore();
    let bodyParameters = {} as InvoiceBodyParam;
    let exportUrl = ref<string>("");
    bodyParameters = {
      per_page: "50",
      agent: "all",
      payment_type: [1, 2, 3, 4],
      is_amend: -1,
      is_discrepancy: 0,
      status: 3,
      keyword: "",
      start_date: "",
      end_date: ""
    };
    let paymentTypes = ref<any>();
   
    
    onMounted(() => {
      if(!roles.declinedInvoice.includes(roles.userId())) window.location.href = '/#/404'
      
      
      MenuComponent.reinitialization();

      setCurrentPageBreadcrumbs("Declined Invoices", ["Invoice"]);
      // API Action to get customers list
      
      if (
        permissions.value.is_admin ||
        permissions.value.is_accountant ||
        permissions.value.is_manager
      ) {
        bodyParameters.agent = "all";
      }
      else
      {
        bodyParameters.agent = "mine";
      }
      
      bodyParameters.keyword = "";
      fetchInvoiceList(bodyParameters);


      Promise.all([MasterService.getPaymentTypes()]).then((data) => {
        if (permissions.value.is_sales_agent || permissions.value.is_manager || permissions.value.is_team_leader) {
          const allData = {
            label: "All Transaction Type",
            value: "mine"
          };
          selectedPaymentType.value = "mine";
          let result = store.getters.getPaymentTypeList;
          result.unshift(allData);
          paymentTypes.value = result;
        }
        if (
          permissions.value.is_admin ||
          permissions.value.is_accountant ||
          permissions.value.is_manager || permissions.value.is_qca
        ) {
          const allData = {
            label: "All Transaction Type",
            value: "all"
          };
          selectedPaymentType.value = "all";
          let result = store.getters.getPaymentTypeList;
          result.unshift(allData);
          paymentTypes.value = result;
        }
      });

      mainSocket
        .channel("policy-channel")
        .listen("CarPolicyCountSocketEvent", (response) => {
          // console.log("policy-channel", response);
          if (response.policy_status) {
            fetchInvoiceList(bodyParameters, false);
          }
        });
    });
    const selectedPaymentType = ref("");
    const getPaginationUrl = (index) => {
      // let pagenationUrl = index.split("=");
      bodyParameters.keyword = "";
      InvoiceService.getInvoiceList(bodyParameters, currentPage.value);
    };
    const onPerPageChange = (event) => {
      bodyParameters.per_page = event.target.value;
      fetchInvoiceList(bodyParameters);
    };

    const fetchInvoiceList = (bodyParameters, reload = true) => {
      currentPage.value = 1;
      bodyParameters.reload = reload
      InvoiceService.getInvoiceList(bodyParameters);
    };
    const fetchInvoiceListPeriod = (listPeriod) => {
      if(listPeriod) {
        var startTS = new Date(listPeriod[0]);
      var endTS = new Date(listPeriod[1]);
      bodyParameters.start_date =
        startTS.getFullYear() +
        "-" +
        (startTS.getMonth() + 1) +
        "-" +
        startTS.getDate();
      bodyParameters.end_date =
        endTS.getFullYear() +
        "-" +
        (endTS.getMonth() + 1) +
        "-" +
        endTS.getDate();
      } else {
        bodyParameters.start_date = '';
        bodyParameters.end_date = '';
      }
      // bodyParameters.keyword = "";
      fetchInvoiceList(bodyParameters);
    };

    const search = ref<string>("");
    const searchInvoice = () => {
      bodyParameters.keyword = search.value;
      //
      fetchInvoiceList(bodyParameters);
    };
    const value2 = ref([]);
    const resetBodyParam = () => {
      value2.value = [];
      search.value = "";
      bodyParameters.per_page = "50";
      
      bodyParameters.payment_type = [1, 2, 3, 4];
      bodyParameters.is_amend = -1;
      bodyParameters.status = 3;
      bodyParameters.keyword = "";
      bodyParameters.start_date = "";
      bodyParameters.end_date = "";

      if (permissions.value.is_sales_agent || permissions.value.is_team_leader) {
        bodyParameters.agent = "mine";
      }
      if (
        permissions.value.is_admin ||
        permissions.value.is_accountant ||
        permissions.value.is_manager
      ) {
        bodyParameters.agent = "all";
      }

      fetchInvoiceList(bodyParameters);
    };
    const exportTransactionApprovals = () => {
      Promise.all([
        InvoiceService.exportTransactionApprovals(bodyParameters)
      ]).then((data) => {
        console.log(data);
        let exportApproval = computed(() => {
          return store.getters.getInvoiceExportApproval;
        });
        exportUrl.value = exportApproval.value;
      });
    };
    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });
    let loadApprovalInformation = computed(() => {
      return store.getters.getApprovalListingDom;
    });
    const userPermission = computed(() => {
      return store.getters.getUserPermissions;
    });
    

    const paginatioObject = computed(() => {
      return store.getters.getInvoicePaginationObject;
    });

    onBeforeRouteLeave(() => {
      store.commit(Mutations.SET_DI_LOADING, true);
    })

    const loading = computed(() => {
      return store.getters.getDiLoading;
    })

    const permissions = computed(() => {
      return store.state.AuthModule.aclPermission;
    });

    const getPaymentTypeFor = (listFor) => {
      //  console.log({payment: listFor.target.value })
      if (listFor.target.value === "all" || listFor.target.value === "mine") {
        bodyParameters.agent = listFor.target.value;
        bodyParameters.payment_type = -1;
        // bodyParameters.keyword = "";
      } else {
        bodyParameters.payment_type = listFor.target.value;
        // bodyParameters.keyword = "";
      }
      fetchInvoiceList(bodyParameters);
    };

    const openOnlinePaymentInfo = (invoice_id) => {
      InvoiceService.getOnlinePaymentInfo({
        online_invoice_id:invoice_id
      }).then(() => {
        InvoiceService.setOnlinePaymentInfoModal(true);
      })
        
      
    };

    const currentPage = ref(1);
    return {
      openOnlinePaymentInfo,
      roles,
      customFunction,
      selectedPaymentType,
      paymentTypes,
      getPaymentTypeFor,
      permissions,
      loading,
      value2,
      currentPage,
      paginatioObject,
      search,
      searchInvoice,
      onPerPageChange,
      getPaginationUrl,
      bodyParameters,
      fetchInvoiceListPeriod,
      resetBodyParam,
      responseResult,
      exportTransactionApprovals,
      disableSaveButton,
      exportUrl,
      loadApprovalInformation,
      userPermission,
    };
  }
});
